import { IconCircleCheck, IconCircleX, IconInfoCircle, IconLoader2 } from "@tabler/icons-react"
import { cn } from "@Utils/theme"
import { removeToast } from "./toastStatic"
import { Toast } from "./types"

type Props = Toast

export default function ToastItem({
  id,
  dismissible,
  type,
  message,
  icon,
  className,
  testID,
}: Props): JSX.Element | null {
  return (
    <div
      role={dismissible ? "alert" : "status"}
      tabIndex={0}
      aria-live={dismissible ? "assertive" : "polite"}
      className={cn(
        "flex items-center gap-[0.8rem] px-[1.6rem] py-[1rem] border-[1px] bg-white text-black text-[1.4rem] leading-[1.8rem] rounded-[0.4rem] shadow-lg opacity-0 animate-fadeInUp transition-all",
        dismissible &&
          "cursor-pointer focus-visible:ring-4 focus-visible:ring-primary-500 focus-visible:ring-opacity-50",
        className
      )}
      onClick={dismissible ? () => removeToast({ id }) : undefined}
      data-testid={testID ?? "toast"}
    >
      <span className="flex-none">
        {icon !== undefined ? (
          icon
        ) : type === "success" ? (
          <IconCircleCheck size={18} className="text-green-500" />
        ) : type === "error" ? (
          <IconCircleX size={18} className="text-red-500" />
        ) : type === "info" ? (
          <IconInfoCircle size={18} className="text-blue-500" />
        ) : (
          type === "loading" && (
            <IconLoader2 className="text-blue-500 animate-spin duration-700" size={18} />
          )
        )}
      </span>
      <span className="flex-1">{message}</span>
    </div>
  )
}
