import React, { ReactNode, useEffect, useReducer, useRef } from "react"
import type { Toast } from "./types"
import { emitter, getInitialToasts, setHostMounted } from "./toastStatic"
import { ToastContext } from "./ToastContext"

enum ToastActionType {
  ADD_TOAST = "ADD_TOAST",
  REMOVE_TOAST = "REMOVE_TOAST",
}

interface AddAction {
  type: ToastActionType.ADD_TOAST
  payload: Toast
}

interface RemoveAction {
  type: ToastActionType.REMOVE_TOAST
  payload: { id: string }
}

type Action = AddAction | RemoveAction

const toastReducer = (state: Toast[], action: Action) => {
  switch (action.type) {
    case ToastActionType.ADD_TOAST:
      return [...state, action.payload]
    case ToastActionType.REMOVE_TOAST:
      return state.filter((toast) => toast.id !== action.payload.id)
    default:
      return state
  }
}

interface ToastProviderProps {
  children: ReactNode
}

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, dispatch] = useReducer(toastReducer, [])
  const toastsRef = useRef(toasts)

  useEffect(() => {
    function addToastAction(toast: Toast) {
      dispatch({ type: ToastActionType.ADD_TOAST, payload: toast })
      toastsRef.current = [...toastsRef.current, toast]
    }

    function removeToastAction(id: string) {
      dispatch({ type: ToastActionType.REMOVE_TOAST, payload: { id } })
      toastsRef.current = toastsRef.current.filter((toast) => toast.id !== id)
    }

    const initialToasts = getInitialToasts()
    if (initialToasts.size > 0) {
      initialToasts.forEach((toast) => {
        addToastAction(toast)
      })
    }

    const timers = new Map<string, NodeJS.Timeout>()

    const handleAddToast = (event: CustomEvent<Toast>) => {
      addToastAction(event.detail)
      if (event.detail.duration) {
        timers.set(
          event.detail.id,
          setTimeout(() => {
            removeToastAction(event.detail.id)
          }, event.detail.duration)
        )
      }
    }

    const handleRemoveToast = (event: CustomEvent<{ id?: string; groupKey?: string }>) => {
      if (event.detail.id) {
        removeToastAction(event.detail.id)
      } else if (event.detail.groupKey) {
        toastsRef.current.forEach((toast) => {
          if (toast.groupKey === event.detail.groupKey) {
            removeToastAction(toast.id)
          }
        })
      }
    }

    emitter.addEventListener("toast", handleAddToast)
    emitter.addEventListener("remove-toast", handleRemoveToast)
    setHostMounted(true)

    return () => {
      emitter.removeEventListener("toast", handleAddToast)
      emitter.removeEventListener("remove-toast", handleRemoveToast)
      timers.forEach((timer) => {
        clearTimeout(timer)
      })
      setHostMounted(false)
    }
  }, [])

  return <ToastContext.Provider value={toasts}>{children}</ToastContext.Provider>
}

export default ToastProvider
