import ReactDOM from "react-dom"
import { useToasts } from "./ToastContext"
import ToastItem from "./ToastItem"

export default function ToastHost(): JSX.Element | null {
  const toasts = useToasts()

  return (
    <div className="z-[9999] fixed top-0 inset-x-0 flex justify-center mt-[2.6rem]">
      <div className="inline-block transform space-y-2 mx-[2rem]">
        {toasts.map((toast) => (
          <ToastItem key={toast.id} {...toast} />
        ))}
      </div>
    </div>
  )
}

// Alternative
export function ToastHostPortal(): React.ReactPortal {
  return ReactDOM.createPortal(<ToastHost />, document.getElementById("toast-root")!)
}
